<template>
  <div class="ranking_box">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="recommend_box">
        <van-sticky>
          <div class="must_buy_tab">
            <van-tabs
              v-model="active"
              color="red"
              line-height="2px"
              line-width="36px"
              title-active-color="#F30002"
              title-inactive-color="#141F33"
              @change="changTab"
            >
              <van-tab
                v-for="(item,index) in dataAll"
                :key="index"
                :name="item.id"
                :title="item.name"
              />
            </van-tabs>
          </div>
        </van-sticky>

        <skeleton v-if="showLoading" type="indexList" />
        <div v-else>
          <div v-for="(item,index) in dataList" :key="index" class="second_kill_list" @click="btnDataList(item)">
            <div class="second_kill_img">
              <imgLoading :src="item.imgs" />
              <!-- <img :src="item.imgs" alt=""> -->
            </div>
            <div class="second_kill_r">
              <div class="h6">{{ item.desc }}</div>
              <div class="second_kill_t">
                <span class="h4">{{ item.couponPrice }} 元券</span>
              </div>
              <div class="second_kill_r_top">
                <div class="second_l">
                  <div class="h6">
                    <span>¥{{ item.actualPrice }}</span>
                    <h6>折后</h6>
                  </div>
                </div>
                <div class="second_r">
                  <div class="second_r_btn">
                    立即抢
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </mescroll>
  </div>
</template>

<script>
import skeleton from '@/component/skeleton/index'
import imgLoading from '@/component/imgLoading'

import { Lazyload, Sticky, Tab, Tabs, Toast } from 'vant'
import Vue from 'vue'
import mescroll from '@/component/fresh_scroll/mescroll'

Vue.use(Tab).use(Tabs).use(Toast).use(Lazyload).use(Sticky)
import { queryRankingMenu, queryRankingList } from '@/services/api'

export default {
  name: 'Ranking',
  components: {
    mescroll, skeleton, imgLoading
  },
  data() {
    return {
      active: '',
      dataAll: '',
      dataList: '',
      id: 1,
      showLoading: false,
      mescrollDown: {
        use: true,
        top: '0px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
    this.gitRankingMenu()
    this.gitRankingMList()
  },
  methods: {
    //  获取  list 列表
    gitRankingMenu: function() {
      queryRankingMenu().then(res => {
        if (Number(res.code) === 200) {
          this.dataAll = res.data
        }
      })
    },
    changTab: function(name, title) {
      this.id = name
      this.gitRankingMList()
    },
    //  获取  list
    gitRankingMList: function() {
      this.showLoading = true
      const data = {
        id: this.id,
        page: 1,
        pageSize: 10
      }
      queryRankingList(data).then(res => {
        if (Number(res.code) === 200) {
          this.dataList = res.data
          this.showLoading = false
        }
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.dataList = []
          // 把请求到的数据添加到列表
          this.dataList = this.dataList.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        id: this.id,
        page: pageNum,
        pageSize: pageSize
      }
      queryRankingList(data).then(res => {
        if (Number(res.code) === 200) {
          successCallback(res.data)
        }
      })
    },
    btnDataList: function(t) {
      // goodsId
      const obj = {
        product_type: 5,
        id: t.id,
        goodId: t.goodsId,
        skuId: '',
        linktype: 55
      }

      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>

<style lang="less" scoped>
.ranking_box {
  .recommend_box {
    background: #f6f6f6;
  }

  background: #f6f6f6;

  .second_kill_list {
    text-align: left;
    margin-left: 12px;
    margin-right: 12px;
    height: 120px;
    background: #fff;
    margin-top: 12px;
    border-radius: 6px;
    overflow: hidden;

    .second_kill_img {
      width: 115px;
      height: 115px;
      padding: 5px;
      box-sizing: border-box;
      float: left;
      overflow: hidden;
      border-radius: 6px;

      img {
        width: 100%;
      }
    }

    .second_kill_r {
      float: left;
      padding: 8px 10px;
      box-sizing: border-box;
      width: 235px;

      .second_kill_t {
        margin-top: 14px;

        .h4 {
          background: #ff5e00;
          font-size: 10px;
          color: #fff;
          padding: 2px 4px;
          box-sizing: border-box;
        }
      }

      .h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
      }

      .second_kill_r_top {
        margin-top: 10px;

        .second_l {
          float: left;

          .second_l_name {
            overflow: hidden;
          }

          .h6 {
            color: #ff5e00;
            font-size: 16px;

            h6 {
              font-size: 10px;
              display: inline-block;
              padding-left: 4px;
              box-sizing: border-box;
            }
          }
        }

        .second_r {
          float: right;
          margin-top: -4px;

          .second_r_btn {
            width: 75px;
            height: 26px;
            line-height: 26px;
            background: #ff5e00;
            border-radius: 12px;
            text-align: center;
            color: #fff;
            font-size: 10px;

          }
        }
      }

    }

  }
}

</style>
